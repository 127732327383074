<template>
  <a-spin tip="Loading..." :spinning="loading">
    <div class="login"></div>
  </a-spin>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { iosLoginOrRegist } from '@/utils'
import storage from 'store'
import { TableClass } from '@/apis/table'

const table = new TableClass()

export default defineComponent({
  setup () {
    const loading = ref(true)

    return {
      loading
    }
  },

  created () {
    const code = this.$route.query.code
    if (code) {
      this.$store.dispatch('WxGZHRegist', code).then(() => {
        iosLoginOrRegist('/scan/regist')
        this.$message.success('登录成功')
        const id = storage.get('SCAN_TABLE_ID', 0)

        table.detail(id).then((resp) => {
          storage.set('SCAN_ORDER_STATE', resp.state)

          if (resp.state === 2) {
            this.$router.push({ name: 'scan-index' })
          } else {
            storage.set('SCAN_PEOPLE_COUNT_' + id, 0)
            this.$router.push({ name: 'scan-people' })
          }
        })
      })
    } else {
      this.$store.dispatch('SnsapiUserinfo2')
    }
  }
})
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
}
</style>
